<template>
<!--  <div class="row m-0 mt-md-5 mb-md-5">-->
  <div class="row m-0 mt-md-5 mb-md-5 m-auto" style="width: 88%">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">关于我们</div>
      <div class="list-group p-0">
        <a href="/company" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心简介</a>
        <a href="/train" class="bg-light font-weight-bold text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">中心领导</a>
        <a href="/funcDep" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">职能部门</a>
        <a href="/leadership" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">领导讲话</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp cen-back bg-lan pl-0 pr-0">
      <div class="text-left p-2 border-bottom border-dark row m-0">
        <div class="col h4 m-0">艺术发展中心领导</div>
        <div class="col text-right pt-1">
          <a href="#" class="text-dark small">详细>></a>
        </div>
      </div>
      <div class="text-left mt-md-2 w-100 p-2">

        <div class="row">
          <div class="card-body col-2">
              <div class="h5 font-weight-bold text-right">
                主任
              </div>
          </div>
          <div class="col-10 row p-0">
            <div class="card-body col-2 m-0 p-3">
              <div class="card">
                <div class="card-body p-0">
                  <a href="javascripy:void(0)" @click="artistById('0b702285-4a55-4cdb-83d6-1475b35ebf5d')"><img class="w-100" src="../../../static/image/people/jianghao2.jpg"></a>
                </div>
                <div class="card-footer bg-secondary text-center text-white mt-0 mb-0 pl-0 pr-0 pt-1 pb-1">江浩</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="card-body col-2">
            <div class="h5 font-weight-bold text-right">
              副主任
            </div>
          </div>
          <div class="col-10 row p-0">
            <div class="card-body col-2 m-0 p-3">
              <div class="card">
                <div class="card-body p-0">
                  <a href="javascripy:void(0)" @click="artistById('ef996da3-395a-400e-b644-13d21a435af5')"><img class="w-100" src="../../../static/image/people/tianyalong.jpg"></a>
                </div>
                <div class="card-footer bg-secondary text-white text-center mt-0 mb-0 pl-0 pr-0 pt-1 pb-1">田亚洲</div>
              </div>
            </div>
            <div class="card-body col-2 m-0 p-3">
              <div class="card">
                <div class="card-body p-0">
                  <a href="javascripy:void(0)" @click="artistById('a8314f06-a157-dfc7-522d-fbe67dc795ed')"><img class="w-100" src="../../../static/image/people/baijianchun2.jpg"></a>
                </div>
                <div class="card-footer bg-secondary text-white text-center mt-0 mb-0  pl-0 pr-0 pt-1 pb-1">白建春</div>
              </div>
            </div>
            <div class="card-body col-2 m-0 p-3">
              <div class="card">
                <div class="card-body p-0">
                  <a href="javascripy:void(0)" @click="artistById('2b1e4530-c3e6-47ce-9d43-8d3718666571')"><img class="w-100" src="../../../static/image/people/libaihu.jpg"></a>
                </div>
                <div class="card-footer bg-secondary text-white text-center mt-0 mb-0 pl-0 pr-0 pt-1 pb-1">李佰虎</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "train",
  data() {
    return {
      noticeData: ''
    }
  },
  mounted() {
  },
  methods: {
    artistById(peoId) {
      this.$router.push({
        path: '/artistByid/',
        query: { peoId: peoId}
      })
    }
  }
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
/*.cen-back {*/
  /*background-image: url("../../../static/image/center_bg.jpg");*/
  /*background-size: 100%;*/
  /*background-repeat: repeat-y;*/
/*}*/
.bg-lan {
  background-color: #BADDFEFF;
  min-height: 630px;
}
</style>